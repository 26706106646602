/* open-sans-regular - latin */
@font-face {
  font-family: 'OPENSANS';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/opensans/open-sans-v16-latin-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/opensans/open-sans-v16-latin-regular.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'OPENSANSMEDIUM';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/opensans/open-sans-v16-latin-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/opensans/open-sans-v16-latin-600.woff') format('woff');
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

body {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

img#mainlogo {
  height: 45px;
  width: auto;
  margin-right: 2em;
}

div.App {
  height: 85vh;
}

div.sticky-container {
  position: sticky;
  z-index: 10;
  top: 0;
}

div.item-container {
  /* height: 100%; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  overflow: auto;
  margin: 2em;
  margin-top: 0;
  width: 95%;
}

.hidetile{
  display: none !important;
}

div.item-container>div.item {
  align-items: center;
  background-color: #F2F2F2;
  color: #333;
  -webkit-box-shadow: 0.07vh 0.07vh 0.42vh 0px rgba(0, 0, 0, 0.45);
  -moz-box-shadow: .07vh .07vh .42vh 0px rgba(0, 0, 0, 0.45);
  box-shadow: 0.07vh 0.07vh 0.42vh 0px rgba(0, 0, 0, 0.45);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  /* min-height: 6em;  */
  margin: 0.3em 0.5em 0.6em 0.5em;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

div.item-container>div.item>img {
  margin: 0.5em;
  width: 5em;
  height: 5em;
  background-color: #ffffff;
}

div.item-container>div.item>div.item-textcontainer {
  display: flex;
  flex-direction: column;
  width: 75%;
}

div.item-container>div.item>div.item-sidecontainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  align-content: stretch;
  height: 100%;
}

div.item-container>div.item>div.item-sidecontainer>div.div-prodnumber {
  width: 1.5em;
  height: 1.5em;
  border-style: solid;
  border-width: 0.12em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  margin-top: 0.3em;
  margin-right: 0.5em;
}


div.item-textcontainer>span.item-heading {
  font-size: 1.1em;
  font-weight: bold;
  font-family: 'OPENSANS';
}

div.item-textcontainer>span.item-location {
  font-size: 0.7em;
  font-family: 'OPENSANS';
}

div.item-textcontainer>span.item-branch {
  font-size: 0.9em;
  font-family: 'OPENSANS';
  margin-top: 0.5em;
}

div.detailcontainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 2em;
}

div.detail_back_container {
  cursor: pointer;
}

div.detail_toprow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
  flex-wrap: wrap;
}

div.detail_bottom {
  width: 100%;
  margin-left: 4.7em;
  margin-top: 2em;
}

div.detail_bottom_subContainer {
  margin-left: 2.3em;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.3em;
}

div.detail_bottom_subContainer>div.detail_bottom_subContainer_text {
  display: flex;
  align-items: center;
}

span.detail_bottom_subheading {
  font-size: 0.9em;
  font-weight: 800;
  font-family: 'OPENSANS';
  line-height: 2em;
  margin-right: 0.2em;
}

div.detail_bottom_button_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
}

span.detail_bottom_heading {
  font-size: 1.1em;
  font-weight: 800;
  font-family: 'OPENSANS';
  line-height: 1em;
}

div.detail_flags_container {
  display: flex;
  flex-direction: column;
}

div.flag {
  width: 18em;
  background-color: #eaeaea;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.3em;
  align-items: center;
  font-size: 0.9em;
  font-weight: 600;
  font-family: 'OPENSANS';
  justify-content: space-between;
}

div.flag>div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.detail_basics_container {
  display: flex;
  flex-direction: column;
}

div.detail_basics_container>img {
  width: 80px;
  margin-bottom: 1em;
}

div.detail_basics_container>div {
  display: flex;
  align-items: center;
}

div.detail_basics_container>div>span.detail-projnumber {
  font-size: 0.9em;
  font-weight: 600;
  font-family: 'OPENSANS';
  margin-right: 0.2em;
}

div.detail_basics_container>div>span.detail-name {
  font-size: 1.2em;
  font-weight: 800;
  font-family: 'OPENSANS';
  line-height: 2em;
  margin-right: 0.2em;
}

div.detail_basics_container>span.detail-location {
  font-size: 0.9em;
  font-weight: 600;
  font-family: 'OPENSANS';
  line-height: 1.7em;
}

div.detail_basics_container>span.detail-branch {
  font-size: 0.9em;
  font-weight: 600;
  font-family: 'OPENSANS';
  line-height: 1.7em;
}

div.detail_basics_container>span.detail-languages {
  font-size: 0.9em;
  font-weight: 600;
  font-family: 'OPENSANS';
  line-height: 1.7em;
  margin-bottom: 1em;
}

div.detail-reference-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

div.detail-reference-subcontainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

div.detail-reference-container>img {
  width: auto;
  max-height: 15em;
}

span.highlight {
  color: #d10000;
  text-decoration: underline;
}


@media screen and (orientation: landscape) {
  div.item-container>div.item {
    /* display: flex; */
    /* width: 37rem; */
    width: 32%;
    min-width: 28rem;
    /* max-height: 6em; */
  }

  div#root {
    font-size: 16px;
  }

  .sideIcons {
    font-size: 2rem !important;
  }
}

@media screen and (orientation: portrait) {
  div.item-container>div.item {
    /* display: flex; */
    width: 100%;
    /* max-height: 6em; */
  }

  div.item-container {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 0;
    width: 100%;
  }

  div.detail_basics_container {
    max-width: 48%;
  }

  div.detail_bottom_button_container {
    width: 100% !important;
  }

  div#root {
    font-size: 12px !important;
  }

  div.detail_toprow {
    justify-content: space-around;
    width: 100%;
  }

  div.detailcontainer {
    margin-left: 1em;
  }

  div.detail_bottom {
    margin-left: 0;
  }

  div.detail_bottom_subContainer {
    margin-left: 0.5em;
  }

  div.detail-reference-container {
    max-width: 48%;
  }

  .back_arrow {
    font-size: 2rem !important;
  }

  .prodbutton {
    font-size: 0.675rem !important;
    min-width: 41% !important;
  }

  .prodIcon {
    width: 0.7em !important;
    height: 0.7em !important;
  }

  .sideIcons {
    font-size: 1.5rem !important;
  }

  body {
    -webkit-user-select: auto;
    /* Safari */
    -ms-user-select: auto;
    /* IE 10 and IE 11 */
    user-select: auto;
    /* Standard syntax */
  }
}